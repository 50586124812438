import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
  }

  increment(e) {
    e.preventDefault();
    this.inputTarget.stepUp();
    this.inputTarget.dispatchEvent(new Event("input"));
  }

  decrement(e) {
    e.preventDefault();
    this.inputTarget.stepDown();
    this.inputTarget.dispatchEvent(new Event("input"));
  }
}
