import { Controller } from "stimulus";
import { useThrottle, useDispatch, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ["subHeader", "subHeaderBlock", "activeItem"];

  static throttles = ['handleScroll'];

  static debounces = ['handleScrollEnd'];

  connect () {
    useThrottle(this, { wait: 50 });
    useDebounce(this, { wait: 50 });
    useDispatch(this);

    this.handleSubHeaderPosition();
    this.updateHeaderNavigationItemStyles();

    this.activeItemTargets.forEach(item => {
      if (item.classList.contains("active")) {
        const offset = item.offsetLeft;
        this.subHeaderTarget.scrollTo({
          left: offset - 25,
        });
      }
    });

  }

  handleScroll = () => {
    this.handleSubHeaderPosition();
  }

  handleScrollEnd = () => {
    this.handleSubHeaderPosition();
  }

  handleSubHeaderPosition() {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 64) {
      this.subHeaderTarget.classList.add('scrolled');
    } else {
      this.subHeaderTarget.classList.remove('scrolled');
    }
  }

  handleSearchButtonClick() {
    this.dispatch('openSearchSidebar');
  }

  fixSubHeader() {
    if (!this.subHeaderTarget.classList.contains('unfixed')) return;

    this.subHeaderTarget.classList.remove('unfixed');
  }

  unfixSubHeader() {
    if (this.subHeaderTarget.classList.contains('unfixed')) return;

    this.subHeaderTarget.classList.add('unfixed');
  }

  updateHeaderNavigationItemStyles() {
    const items = document.querySelectorAll('.sub-header-navigation-list-item');
  
    items.forEach(item => {
      const { height } = item.getBoundingClientRect();
      
      item.classList.toggle('adjust-top', height > 16);
    });
  }
}
