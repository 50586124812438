export const BOOKING_FEE_ITEM_ID = 108;
// TODO: Make a function in case this content is dependent on backend data
export const BOOKING_FEE_TOOLTIP_CONTENT = `
  <div class="tooltip-box" data-target="pdp-booking-sidebar.tooltipButton">
    <div class="tooltip-content" data-target="pdp-booking-sidebar.tooltipText">
      <div class="tooltip-text">Covers all fees and taxes related to cleaning, processing & protection, and resort benefits/ services</div>
    </div>
    <img class="tooltip-svg" data-action="click->pdp-booking-sidebar#handleClickTooltip" src="/images/tooltip.svg"></img>
  </div>
`
export const errorMessageToReplace = 'Provided unit or unit type is not available for the dates provided.';
export const errorMessageForReplacing = "This unit is not available for the dates you've selected";