import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["rateDetailsCollapse"];

  toggleRateDetailsCollapse() {
    this.rateDetailsCollapseTarget.classList.toggle('hidden');
  }
}
