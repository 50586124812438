import Flatpickr from "stimulus-flatpickr";
import {useDispatch} from "stimulus-use";
import {prevArrowIcon, nextArrowIcon} from "./constants";
import getAvailability, {getAvailabilityDate} from "util/availability";
import {DateTime} from "luxon";
import {deleteCookie} from "init/cookie";
import getFilters from "util/filter_params";

export default class extends Flatpickr {
    static targets = [
        "arriveInput",
        "departInput",
        "resetDatesBtn",
        "rangeBlock"
    ];

    availabilityData = [];

    temporaryDisabledDates = [];

    checkin = null;

    checkout = null;

    showMinStay = true;

    initialize() {
        const isMobile = window.matchMedia("(max-width: 700px)").matches;
        const filters = getFilters({
            checkin: this.arriveInputTarget.value,
            checkout: this.departInputTarget.value
        });

        this.config = {
            mode: "range",
            minDate: "today",
            showMonths: isMobile ? 1 : 2,
            monthSelectorType: "static",
            inline: true,
            position: "above",
            shorthandCurrentMonth: true,
            prevArrow: prevArrowIcon,
            nextArrow: nextArrowIcon,
            locale: {
                firstDayOfWeek: 0, // start week on Saturday
                weekdays: {
                    shorthand: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
                }
            },
            dateFormat: "Y-m-d",
            defaultDate: [filters.get("checkin"), filters.get("checkout")],
            disable: [this.disabledDates.bind(this)]
        };
        if (document.body.classList.contains("template-home") ||
            document.body.classList.contains("default")) {
            this.showMinStay = false;
        }
    }

    connect() {
        useDispatch(this);

        const availFilters = JSON.parse(this.element.dataset.availFilter);
        getAvailability(availFilters).then(data => {
            this.availabilityData = data;
            this.config.maxDate = data[data.length - 1].date;
            super.connect();
        }).catch(err => console.log(err));
    }

    ready(selectedDates, dateStr, instance) {
        const {calendarContainer: container} = instance;
        this.applyNumberOfMonth(container, instance);
    }

    resetDates(e) {
        e.stopPropagation();
        const url = new URL(window.location);
        url.searchParams.delete('checkin');
        url.searchParams.delete('checkout');
        deleteCookie("filter_by_dates");

        this.arriveInputTarget.setAttribute('value', "");
        this.departInputTarget.setAttribute('value', "");
        [this.checkin, this.checkout] = [false, false];

        window.history.replaceState({path: url.toString()}, "", url.toString());
        this.clearDates();
        this.dispatch('datesCleared')
        document.dispatchEvent(new CustomEvent('filters:clearDates'));
    }

    change(selectedDates) {
        [this.checkin, this.checkout] = selectedDates;
        if (!this.checkin) return;
        if (!this._isDateCanCheckin(DateTime.fromJSDate(this.checkin))) {
            this._clearDates();
            return;
        }

        if (this.checkout) {
            if (this.checkin.toDateString() === this.checkout.toDateString()) {
                this._clearDates();
                return;
            }
            this.temporaryDisabledDates = [];
            this.last_checkout = DateTime.fromJSDate(this.checkout);
            [this.checkin, this.checkout] = [false, false];
            this.fp.redraw();
            return;
        }

        this.handleSelectedCheckIn();
        this.dispatch("change");
    }

    _clearDates() {
        this.temporaryDisabledDates = [];
        [this.checkin, this.checkout] = [false, false];
        this.last_checkout = null;
        this.availabilityCalendarMap.clear();
        this.fp.clear(true, false);
    }

    close(selectedDates) {
        if (selectedDates[0].toDateString() !== selectedDates[1].toDateString()) {
            this.updateDateInputs(selectedDates);
            this.dispatch("close");
        }
    }

    today = DateTime.now().setZone("America/New_York");
    availabilityCalendarMap = new Map();
    last_checkout = null;

    disabledDates(date) {
        const jsDate = DateTime.fromJSDate(date);
        const dateBlocked = this._dateBlocked(jsDate);
        this.availabilityCalendarMap.set(jsDate.valueOf(), !dateBlocked);
        return dateBlocked;
    }

    dayCreate(dObj, dStr, fp, dayElem) {
        const date = DateTime.fromJSDate(dayElem.dateObj);

        let [checkin, checkout] = dObj;
        if(checkin) { checkin = DateTime.fromJSDate(checkin); }

        const minStay = this._dateMinStay(date)

        if (this.showMinStay && minStay &&
            ((checkin && checkin.hasSame(date, 'day')) || (!checkin || (checkin && checkout)))) {
            dayElem.setAttribute("data-popover", `Min ${minStay} nights required`);
        }

        // RULES:

        // 1. If it has a checkin date, and it is included on min stay range,
        //    mark as "not-available"
        if (
            this.checkin &&
            !this.checkout &&
            this.temporaryDisabledDates.some(item => item.hasSame(date, "day"))
        ) {
            // eslint-disable-next-line no-param-reassign
            dayElem.classList.add("not-available");
            dayElem.removeAttribute("data-popover");
        }

        // 2. If it is a checkin-only date, mark it as back to back
        if (!this._isDateAvailable(date) && this._isDateCanCheckout(date)) {
            dayElem.classList.add("flatpickr-back-to-back");
            dayElem.setAttribute("data-popover", "Checkout Only");
        }

        // 3. If it is available but can't be a checkin
        if (
            !this.checkin &&
            this._isDateAvailable(date) &&
            !this._isDateCanCheckin(date)
        ) {
            dayElem.classList.add("flatpickr-checkin-notAllowed");
            // dayElem.setAttribute("data-popover", "Arrival Closed");
        }

        // 5. If it is available but can't be a checkout
        if (
            this.checkin &&
            this._isDateAvailable(date) &&
            !this._isDateCanCheckout(date)
        ) {
            dayElem.classList.add("flatpickr-checkout-notAllowed");
            dayElem.setAttribute("data-popover", "Departure Closed");
        }
    }

    /**
     * Determines if a given date should be blocked based on specific rules.
     *
     * @param {Date} date - The date to check.
     * @returns {boolean} - true if the date should be blocked, false otherwise.
     */
    _dateBlocked(date) {
        // RULES:

        // 1. If it has a checkin selected, block all dates before the checkin
        if (this.checkin && date < this.checkin) return true;

        // 2. If it has a checkout selected and is the same of the current date,
        //    don't block
        if (this.last_checkout && date.hasSame(this.last_checkout, "day"))
            return false;

        // 3. If the date is today and the current hour is more than 12 in
        //    New York timezone, block
        if (this.today.hasSame(date, "day") && this.today.hour >= 12) {
            return true;
        }

        // set current and previous date availability
        const dateAvail = this._isDateAvailable(date);
        const prevDayAvail = this._isDateAvailable(date.minus({day: 1}), true);

        // 4. If the date is available,
        //    but the prev and next day are unavailable, block
        if (dateAvail && !prevDayAvail) {
            const nextDayAvail = this._isDateAvailable(date.plus({day: 1}), true);
            if (!prevDayAvail && !nextDayAvail) return true;
        }

        // 5. If the date is available, the prev date is not and
        //    can't be a checkin date, block
        if (dateAvail && !prevDayAvail && !this._isDateCanCheckin(date))
            return true;

        // 6. If the date is unavailable but a checkin date is selected
        //    and the previous day is available, don't block
        if (!dateAvail &&
            this.checkin &&
            this._isDateAvailable(date.minus({day: 1}))) return false;

        // 7. If the date is unavailable, block
        return !dateAvail;
    }

    /**
     * Checks if a given date is available based on the availability calendar.
     * @param {Date} date - The date to be checked for availability.
     * @param {boolean} [checkMap=false] - Optional parameter to specify whether to check the cached availability map or not.
     * @return {boolean} - Returns a boolean value indicating if the date is available or not.
     */
    _isDateAvailable(date, checkMap = false) {
        if (checkMap && this.availabilityCalendarMap.has(date.valueOf())) {
            return this.availabilityCalendarMap.get(date.valueOf());
        }

        const dateAvail = getAvailabilityDate(
            date.toFormat("yyyy-MM-dd"),
            this.availabilityData
        );
        return dateAvail && dateAvail.avail;
    }

    /**
     * Checks if a given date is available for check-in.
     *
     * @param {DateTime} date - The date to be checked.
     * @return {boolean} - true if the date is available for checkin, false otherwise.
     */
    _isDateCanCheckin(date) {
        const dateAvail = getAvailabilityDate(
            date.toFormat("yyyy-MM-dd"),
            this.availabilityData
        );

        if (!dateAvail) return false;

        let check = [dateAvail.checkin_allowed];
        const nights = dateAvail.min_stay - 1;
        for (let i = 1; i <= nights; i++) {
            check.push(this._isDateAvailable(date.plus({day: i})));
        }

        return check.every(v => v);
    }

    /**
     * Checks if the given date is available for checkout.
     *
     * ATTENTION NOTE: Early check-in is not considered.
     *
     * @param {DateTime} date - The date to be checked.
     * @return {boolean} true if the date is available for checkout, false otherwise.
     */
    _isDateCanCheckout(date) {
        const dateAvail = getAvailabilityDate(
            date.toFormat("yyyy-MM-dd"),
            this.availabilityData
        );

        if (!dateAvail || !dateAvail.checkout_allowed) return false;

        if (this._isDateAvailable(date)) return true;
        return (
            this._isDateAvailable(date.minus({day: 1})) &&
            this._isDateAvailable(date.minus({day: 1}), true)
        );
    }

    _dateMinStay(date) {
        const dateAvail = getAvailabilityDate(
            date.toFormat("yyyy-MM-dd"),
            this.availabilityData
        );

        if (!dateAvail || !dateAvail.checkout_allowed) return false;

        return dateAvail.min_stay
    }

    handleSelectedCheckIn() {
        const date = DateTime.fromJSDate(this.checkin);

        const day = getAvailabilityDate(
            date.toFormat("yyyy-MM-dd"),
            this.availabilityData
        );

        const blockDays = [];
        do {
            blockDays.push(date.plus({day: blockDays.length + 1}));
        } while (blockDays.length < day.min_stay - 1);

        this.temporaryDisabledDates = blockDays;
        if (blockDays.length > 0) this.fp.redraw();

        // move month ahead if needed
        const nextAvailDay = date.plus({day: blockDays.length});
        const targetMonth =
            this.fp.config.showMonths === 1
                ? this.fp.currentMonth
                : this.fp.currentMonth + 1;
        if (nextAvailDay.month - 1 > targetMonth) {
            this.fp.changeMonth(1);
        }
    }

    applyNumberOfMonth(container, fp) {
        if (this.data.get("showOneMonth")) {
            fp.set("showMonths", 1);
            container.classList.add("one-month-only");
        }
    }

    clearDates() {
        this.fp.clear();
        this.fp.redraw();
    }

    updateDateInputs(dates) {
        this.arriveInputTarget.value = DateTime.fromJSDate(dates[0]).toFormat(
            "yyyy-MM-dd"
        );
        this.departInputTarget.value = DateTime.fromJSDate(dates[1]).toFormat(
            "yyyy-MM-dd"
        );
        this.arriveInputTarget.dispatchEvent(new Event("input"));
    }

    setSelectedDates() {
        const dates = [this.arriveInputTarget.value, this.departInputTarget.value];
        this.fp.setDate(dates, false, "Y-m-d");
        this.updateMonths();
    }

    updateMonths() {
        const [startDate] = this.fp.selectedDates;
        const dateToJump = startDate || new Date();
        this.fp.jumpToDate(dateToJump, false);
    }
}
