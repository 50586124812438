import { Controller } from "stimulus";

import { submitForm } from "init/ajax";
import { parentModal, loadModalUrl } from "../modal/utils";

export default class extends Controller {
  modalLink(event) {
    event.preventDefault();

    loadModalUrl(event.target.href, { replace: this.element });
  }

  async submit(event) {
    event.preventDefault();

    const form = event.target;
    // Consider everything valid if browser validations aren't supported
    if (!form.reportValidity || form.reportValidity()) {
      const responseHTML = await submitForm(form);

      window.dataLayer.push({
        event: "GTMevent",
        event_name: 'login',
        user_id: responseHTML.user_id,
        event_data: {},
        _clear: true
      });

      parentModal(this.element).replaceWith(
        (responseHTML && responseHTML.querySelector(".modal")) || ""
      );
    }
  }
}
