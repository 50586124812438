function buildUrl(filters = {}) {
  const url = new URL(
    "/api/availability.json",
    `${window.location.protocol}//${window.location.host}`
  );
  ["start_date", "end_date", "city", "resort", "id", "type"].forEach(param => {
    if (filters[param]) {
      url.searchParams.append(param, filters[param]);
    }
  });

  return url.toString();
}

function getCachedAvailability(url) {
  const rawCache = localStorage.getItem("availability");

  if (!rawCache) {
    return null;
  }

  const cache = JSON.parse(rawCache);

  if (cache.url !== url) {
    return null;
  }

  const expireDate = new Date();
  expireDate.setMinutes(expireDate.getMinutes() + 5);
  if (cache.created >= expireDate) {
    localStorage.removeItem("availability");
    return null;
  }

  return cache.data;
}

function setCachedAvailability(url, data) {
  const cachedValue = {
    created: new Date(),
    url,
    data
  };
  localStorage.setItem("availability", JSON.stringify(cachedValue));
}

export default async function getAvailability(filters = {}) {
  const url = buildUrl(filters);

  const cached = getCachedAvailability(url);
  if (cached) return cached;

  const response = await fetch(url);
  const data = await response.json();

  setCachedAvailability(url, data);
  return data;
}

export function getAvailabilityDate(dateString, availability) {
  return availability.find(item => item.date === dateString);
}
