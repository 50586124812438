import {getCookie} from "init/cookie";

/**
 * Retrieves the filters from the URL parameters, with the optional ability to
 * overwrite and force update the URL.
 *
 * @param {Object} overwrite - An object containing key-value pairs to
 * overwrite the current URL parameters.
 * @param {boolean} force - A boolean value indicating whether to force to update
 * the URL with the new filters.
 *
 * @return {URLSearchParams} - The URL parameters representing the filters.
 */
export default function getFilters(overwrite = {}, force = false) {
    const urlParams = new URLSearchParams(window.location.search);

    // if checkin or checkout doesn't exist, try fetch from cookie
    if (!urlParams.has('checkin') || !urlParams.has('checkout')) {
        const datesFromCookie = JSON.parse(getCookie('filter_by_dates') || '{}')
        if (!urlParams.has('checkin')
            && _isValidValue(datesFromCookie.checkin)) {
            urlParams.set('checkin', datesFromCookie.checkin)
        }
        if (!urlParams.has('checkout')
            && _isValidValue(datesFromCookie.checkout)) {
            urlParams.set('checkout', datesFromCookie.checkout)
        }
    }

    // if adults or children don't exist, try fetch from cookie
    if (!urlParams.has('adults') || !urlParams.has('children')) {
        const guestsFromCookie = JSON.parse(getCookie('filter_by_guests') || '{}')
        if (!urlParams.has('adults')
            && _isValidGuestCount(guestsFromCookie.adults, ["2"])) {
            urlParams.set('adults', guestsFromCookie.adults);
        }
        if (!urlParams.has('children')
            && _isValidGuestCount(guestsFromCookie.children)) {
            urlParams.set('children', guestsFromCookie.children);
        }
    }

    if (overwrite) {
        Object.keys(overwrite).forEach(key => {
            if (_isValidValue(overwrite[key])) {
                if ((key === 'adults' && ["0", "2"].includes(overwrite[key])) ||
                    (key === 'children' && overwrite[key] === "0")) {
                    urlParams.delete(key);  // Skip adding guests with value 0 to URL
                } else {
                    urlParams.set(key, overwrite[key]);
                }
            }
        });
    }

    if (force && urlParams.size >= 1) {
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({path: newUrl}, '', newUrl);
    }

    return urlParams
}

function _isValidValue(value) {
    const skip = [null, undefined, '']
    return !skip.includes(value)
}

function _isValidGuestCount(value, additionalIgnoreList = [] ) {
    return _isValidValue(value) && value > 0 && !additionalIgnoreList.includes(value);
}
