import { Controller } from "stimulus";
import { postJson } from "init/ajax";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default class extends Controller {
  static targets = ["email", "optin", "optinContainer"];

  validate() {
    let valid = true;
    const validators = [
      {
        condition: !this.optinTarget.checked,
        target: this.optinContainerTarget
      },
      {
        condition: !emailRegex.test(this.emailTarget.value),
        target: this.emailTarget
      }
    ];
    validators.forEach(validator => {
      if (validator.condition) {
        validator.target.classList.add("error");
        valid = false;
      } else {
        validator.target.classList.remove("error");
      }
    });
    return valid;
  }

  submit(e) {
    e.preventDefault();
    e.returnValue = false;

    const valid = this.validate();
    if (!valid) return;

    // We're valid and doing form submission, now's a good time to hide the newsletter modal for good.
    document.cookie = `hasSeenModalOptin="true"; expires="2038-01-19 04:14:07"; path=/`;

    const { url } = this.element.dataset;
    const source = this.element.closest(".modal-newsletter")
      ? this.element.querySelector("[name='source']").value
      : null;
    const data = {
      email: this.emailTarget.value,
      source
    };
    const callback = this.success.bind(this);

    postJson(url, data, callback);

    setTimeout(() => {
      if (e.target.dataset.redirect) {
        window.location.href = e.target.dataset.redirect;
      }
    }, 5000);
  }

  success() {
    // GTM DataLayer: Push "subscribe" event
    const email = this.emailTarget.value;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "subscribe",
      subscription_email: email
    });

    window.dataLayer.push({
      event: "GTMevent",
      event_name: 'email_signup',
      event_data: {},
      _clear: true
    });

    this.emailTarget.value = "";
    this.element.classList.add("success");
    const outerModal = document.querySelector(".newsletter-modal");
    const innerModal = document.querySelector(".modal-newsletter");
    const $this = this;

    outerModal.classList.add("fadeOut");
    innerModal.classList.add("success");

    // eslint-disable-next-line func-names
    setTimeout(function() {
      if (!$this.element.closest(".modal-newsletter")) {
        $this.element.classList.remove("success");
      }
    }, 5000);
  }
}
